import "./footer.scss";

function Footer() {
  return (
    <div className="footer">
      <p className="footerParagraph">2024 Fawaz Oyedeji by Zainab Oyedeji</p>
    </div>
  );
}

export default Footer;
